import $ from 'jquery';

const domain = "https://api.opensea.io/api/v1/";

export function checkNFTs(owner, collection_slug, cb){
	sendGET(
		domain+'assets/', 
		{
			owner,
			collection_slug
		}, 
		(data)=>{
			cb(data.assets.length>0);
		}
	);
	
}

export function getAllNFTs(owner, cb){
	sendGET(
		domain+'assets/', 
		{
			owner,
		}, 
		(data)=>{
			var res = [];

			data.assets.forEach(obj=>{
				res.push({
					id: obj.id+obj.name,
					name: obj.name,
					img: obj.image_url,
					collection: obj.collection.slug
				});
			});

			cb(res);
		}
	);
}

function sendGET(url, data, cb){
	$.ajax({
		type: "GET",
		url: url,
        data: data,
        success: (data)=>{
        	cb(data);
        },
        error: (err)=>{
        	console.log(err);
        }
	});
}


export function getProducts(cb){
	$.getJSON('/blueprint.json', (data) => {
	  cb(data);
	});
  }