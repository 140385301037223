<template>
  <ul class="products">
      <li @click="selectNew(product)" v-for="product in products" :key="product.name" 
        :class="['products__item', {'products__item_active': chosen.name === product.name}]" 
        :style="`background-image: url(${getImage(product)})`"
      >
      </li>
    </ul>
</template>

<script>
export default {
  props: {
    products: Array,
    chosen: Object
  },
  emits:{
    'chosenChanged': true
  },
  methods:{
    getImage(product){
      return product.views[0].background.img;
    },
    selectNew(obj){
      this.$emit('chosenChanged',obj);
    }
  },

}
</script>

<style lang="scss" scoped>
  .products {
    display: grid;
    grid-area: products;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 7px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 400px;
    &__item {
      position: relative;
      width: 60px;
      height: 60px;
      &::before {
        display: none;
        position: absolute;
        content: '';
        top: -6px;
        left: -6px;
        width: 72px;
        height: 72px;
        border: 1px solid gray;
      }
      &:hover::before {
        display: block;
      }
      &_active::before {
        display: block;
        border: 1px solid #000;
      }
    }
  }

  li{
    background: no-repeat center #fff;
    background-size: contain;
    cursor: pointer;
  }
</style>