<template>
  <div class="preview">
    <image-editor :objects="nfts" :product="activeProduct" ref="editor" />
    <div class="preview__control">
      <button
        v-for="i in views"
        :key="i"
        class="preview__btn preview__btn_front"
        :class="{ 'preview__btn_active': activeView == i }"
        @click="changeView(i)"
      >{{ i }}</button>
    </div>
  </div>
</template>

<script>
import ImageEditor from "@/components/ImageEditor.vue";

export default {
  components: {
    ImageEditor
  },
  props: {
    nfts: Array,
    product: Object
  },
  data() {
    return {
      activeView: this.product.views[0].name
    }
  },
  computed: {
    views() {
      return this.product.views.map(e => e.name);
    },
    activeProduct() {
      return this.product.views.find(e => e.name == this.activeView);
    }
  },
  methods: {
    changeView(i){
      this.activeView = i;
    },
    getPrint(cb) {
      this.$refs.editor.getResultNFT((d) => {
        var res = {
          "product": {
            "static": {
              [this.activeView]: {
                "image_base64": this.activeProduct.background.img // Картинка одежды без принта
              },
              "size": {
                "w": 1000,
                "h": 1000
              }
            },
            "complate": {
              [this.activeView]: {
                "base64": d.photo //Картинка одежды + NFT
              },
            }
          },
          "nft": d.nft
        };
        cb(res);
      })
    }
  },
  watch:{
    product:{
      handler(next){
        this.changeView(next.views[0].name)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.preview {
  grid-area: preview;
  position: relative;
  height: 500px;
  &__control {
    position: absolute;
    top: 24px;
    left: 24px;
  }
  &__btn {
    margin-right: 8px;
    padding: 4px 16px;
    border: none;
    background-color: transparent;
    border-radius: 0;
    font-family: inherit;
    cursor: pointer;
    &_active {
      border: 1px solid #000;
    }
  }
}
</style>