<template>
  <div class="wrapper">
    <div class="builder">
      <template v-if="!address">
        <div class="topCaption">
           <template v-if="isMetaMaskInstalled">
            <h1 class="title">CONNECT YOUR WALLET</h1>
            <p class="caption">Connect to a MetaMask wallet to use the Merch builder</p>
            <button class="button" @click="connect">Connect wallet</button>
            <div v-if="unloggedErr">Check if you are logged in to the Metamask Extension</div>
          </template>
          <template v-else>
            <div class="input-wrapper" style="text-align: center">
              Please use Firefox or a Chromium-based browser with
              <a href="https://metamask.io/" target="_blank" >Metamask hot wallet</a>
              installed
            </div>
          </template>
        </div>
      </template>

      <template v-else>
        <template v-if="hasToadzRequest">
          <h1>Checking your NFTs...</h1>
        </template>
        <template v-if="!hasToadzRequest && !hasToadz">
          <div class="topCaption">
            <h1 class="title">NO TOADHEADZ</h1>
            <p class="caption">
              You haven't got ToadHeadz in your wallet.
              <br />Mint it to get access to the print-builder
            </p>
            <a href="/" class="button">Go to Main</a>
          </div>
        </template>
        <template v-if="!hasToadzRequest && hasToadz">
          <div class="builder__grid" v-if="chosenProduct">
            <products :products="products" :chosen="chosenProduct" @chosenChanged="(d)=>{chosenProduct = d}"></products>
            <palette :colors="chosenProduct.colors" :chosen="chosen.color" @chosenChanged="(d)=>{chosen.color = d}"></palette>
            <preview :nfts="editorNFTs" :product="chosenProduct" ref="preview"></preview>
            <info :allNFTs="allNFTs" :name="chosenProduct.name" :chosenNFTs="chosen.NFTs"></info>
            <order 
              :data="{
                sizes: chosenProduct.sizes,
                number: chosen.number,
                size: chosen.size
              }"
              @changeNumber="(n)=>{chosen.number = n}"
              @changeSize="(n)=>{chosen.size = n}"
              @triggerConfirm="confirmHandler"  
            > 
            </order>
          </div>
        </template>
      </template>
    </div>
    <div v-if="address" class="log-out">
      <img src="../assets/profile.svg" alt="profile icon" />
      <span class="log-out__wallet">{{ addresCropped }}</span>
      <button class="log-out__btn" @click="logout">Log out</button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Products from "@/components/Products.vue";
import Palette from "@/components/Palette.vue";
import Preview from "@/components/Preview.vue";
import Info from "@/components/Info.vue";
import Order from "@/components/Order.vue";

import { checkNFTs, getAllNFTs, getProducts } from "@/script/api.js";
import $ from "jquery";

var { ethereum } = window;
export default {
  components: {
    Products,
    Palette,
    Preview,
    Info,
    Order,
  },
  data() {
    return {
      unloggedErr: false,
      hasToadzRequest: true,
      hasToadz: false,
      address: "",

      allNFTs: [],
      chosenNFTs: [],

      products: [],
      chosenProduct: null,

      chosen: {
        name: null,
        size: null,
        color: null,
        number: null,
        NFTs: []
      }
    };
  },
  mounted() {
    if (localStorage.address) {
      this.address = localStorage.address;
      this.firstRequest();
    }

    getProducts((data) => {
      this.products = data;
      this.chosenProduct = data[0];
    });
  },
  methods: {
    connect() {
      ethereum
        .request({ method: "eth_requestAccounts" })
        .then(this.handleAccountsChanged)
        .catch((error) => {
          if (error.code === 4001) {
            console.log("Please connect to MetaMask.");
          } else if (error.code === -32002) {
            ethereum.request({ method: 'wallet_requestPermissions',params: [{ eth_accounts: {}}]})
            this.unloggedErr = true;
          } else {
            console.error(error);
          }
        });
    },
    async handleAccountsChanged(address){
      this.address = address[0];
      this.firstRequest();
    },
    firstRequest() {
      checkNFTs(this.address, "toadheadz", (resp) => {
        this.hasToadzRequest = false;
        this.hasToadz = resp;
      });
    },
    secondRequest() {
      getAllNFTs(this.address, (resp) => {
        this.allNFTs = resp;
      });
    },

    refresh(){
      this.chosen.name = this.chosenProduct.name;
      this.chosen.size = this.chosenProduct.sizes[0];
      this.chosen.color = this.chosenProduct.colors[0];
      this.chosen.number = 1;
      this.chosen.NFTs = [];
    },

    logout(){
      localStorage.removeItem('address');
      location.reload();
    },

    confirmHandler(){
      this.$refs.preview.getPrint((d)=>{
        var res = {
          parametrs:{
            type: this.chosen.name,
            size: this.chosen.size,
            color: this.chosen.color,
            number: this.chosen.number
          },
          print: d
        }

        console.log(res);
      })
    },
  },
  computed: {
    isMetaMaskInstalled() {
      return ethereum && ethereum.isMetaMask;
    },

    editorNFTs() {
      var res = [];
      this.chosen.NFTs.forEach((i) => {
        var obj = i;

        obj.w = $(`[picid="${obj.id}"]`).width();
        obj.h = $(`[picid="${obj.id}"]`).height();

        res.push(obj);
      });
     
      return res;
    },

    addresCropped(){
      return '0x...'+this.address.slice(-4);
    }
  },
  watch:{
    address(){
      localStorage.address = this.address;
    },
    hasToadz(){
      if(this.hasToadz)this.secondRequest();
    },
    chosenProduct(){
      this.refresh();
    }
  }
};
</script>

<style lang="scss">
* {
  scrollbar-width: thin;
  scrollbar-color: gray transparent;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 20px;
  }
  &:focus-visible{
    outline: none;
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #bfbb98;
}
.builder {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 140px;
  height: 100vh;
  &__grid {
    max-width: 1154px;
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-areas: "products preview info" "palette preview params";
    grid-template-columns: 240px 500px 233px;
    grid-template-rows: 1fr 235px;
    column-gap: 60px;
  }
}

.caption {
  text-align: center;
}

.label {
  font-size: 15px;
  line-height: 20px;
}

.select,
.input {
  padding: 11px 32px 10px 12px;
  width: 100%;
  font-family: inherit;
  font-size: 15px;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 0;
}

.button {
  padding: 10px;
  width: 100%;
  background: transparent;
  border: 1px solid black;
  font-family: inherit;
  font-size: 16px;
  line-height: 25.6px;
  cursor: pointer;
  &:hover {
    color: rgb(176, 78, 133);
    border: 1px solid rgb(176, 78, 133);
  }
  &__order {
    border: none;
    color: #fff;
    background-color: #b04e85;
    &:hover {
      border: none;
      color: #fff;
      background-color: #c95998;
    }
  }

  &:is(a){
    color: #000;
    text-decoration: none;
    display: inline-block;
  }
}

.log-out {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  align-self: center;
  gap: 6px;
  &__btn {
    margin-left: 6px;
    font-size: 15px;
    line-height: 20px;
    background: transparent;
    border: none;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      color: #00000060;
    }
  }
  &__wallet {
    font-weight: bold;
    text-decoration: underline;
  }
}

.topCaption{
  text-align: center;

  h1{
    font-size: 40px;
  }

  .button{
      width: max-content;
      min-width: 220px;
  }
}

// .title {
//   display: block;
//   text-align: center;
// }
</style>