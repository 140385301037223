<template>
    <h3>How to use:</h3>

    <div class="global_wr">
        <div class="col">
            <div class="input_wr">Name: <input type="text" placeholder="Unisex Heavy Cotton Tee" v-model="prodName"></div>
            <div class="input_wr">
                Sizes:
                <div class="brd_container">
                    <label v-for="s in sizes" :key="s.value"><input type="checkbox" v-model="s.selected" :value="s.value">{{s.value}}</label>
                </div>
            </div>
            <div class="input_wr">
                Colors:
                <div class="brd_container">
                    <div>
                        <input type="text" placeholder="#FFFFFF, #000000" v-model="textColors">
                        <div class="colors">
                            <div class="block" v-for="i in colors" :key="i" :style="`background:${i}`"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="input_wr">
                Views:
                <div class="views">
                    <div class="input_wr"><input type="text" v-model="textViews" @input="refreshViews"></div>
                    <div class="block" v-for="i in views" :key="i.name">
                        <div class="input_wr">{{i.name}} <input type="file" @change="(e)=>{fileChangeHanler(e,i.name)}" accept="image/png, image/jpeg"  placeholder="link to image"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col" v-if="textViews">
            <div class="viewSwitch">
                <label v-for="i in views" :key="i.name" :active="viewShown == i.name||null">
                    <input type="radio" v-model="viewShown" :value="i.name">
                    {{i.name}}
                </label>
            </div>
            <div v-for="i in views" :key="i.name" class="container" :undisplayed="viewShown != i.name||null">
                <a class="fixedDirections" @click="horisontalCenter(i.name)">Center</a>
                <img v-if="i.img" :src="i.img" alt="">
                <vue-drag-resize-rotate
                    :x="i.static.x"
                    :y="i.static.y"
                    :w="i.static.w"
                    :h="i.static.h"
                    :isRotatable="true"
                    :parentLimitation="true"
                    :contentClass="`vdr${i.name}`"

                    @resizing="(data)=>{movedHandler(data,i.name)}" 
		            @dragging="(data)=>{movedHandler(data,i.name)}"
                >
                    <div class="area"></div>
                </vue-drag-resize-rotate>

                <div v-if="!i.img" class="text">
                    <h4>Upload picture first</h4>
                </div>
            </div>
        </div>
    </div>

    <h3>Result</h3>
    <div class="btn" @click="copyHandler">Copy result to clipboard</div>
    <!-- <div class="result">
        {{JSON.stringify(result)}}
    </div> -->
   



    
</template>

<script>
    import $ from 'jquery';
    import VueDragResizeRotate from '@pinkcao/vue-drag-resize-rotate/packages/vueDragResizeRotate/vueDragResizeRotate';
    import {fileToURL} from '@/script/toDataUrl.js'
    
    export default{
        components:{
            VueDragResizeRotate,
        },
        data(){
            return {
                views:[
                    {name: 'front', img: '', x:200, y:200, w:100, h: 100, static: {x:200, y:200, w:100, h: 100}},
                    {name: 'back', img: '', x:200, y:200, w:100, h: 100, static: {x:200, y:200, w:100, h: 100}},
                ],

                textColors: '',
                textViews: 'front, back',
                
                prodName: '',

                sizes: [
                    {value: "XS", selected: false}, 
                    {value: "S", selected: false}, 
                    {value: "M", selected: false}, 
                    {value: "L", selected: false}, 
                    {value: "XL", selected: false}, 
                    {value: "2XL", selected: false}, 
                    {value: "4XL", selected: false}, 
                    {value: "5XL", selected: false},
                    {value: "One size", selected: false},
                ],

                viewShown: 'front',  
            }
        },
        computed:{
            colors(){
                return [...new Set(this.textColors.replaceAll(' ','').split(',').filter(e => this.validColor(e)))];
            },

            result(){
                return{
                    name: this.prodName,
                    sizes: this.sizes.filter(e => e.selected).map(e => e.value),
                    colors: this.colors,
                    views: this.views.map(e => {
                        return {
                            name: e.name,
                            background: {
                                h:1000, 
                                w:1000,
                                img: e.img
                            },
                            area: {
                                w: e.w*2,
                                h: e.h*2,
                                x: e.x*2,
                                y: e.y*2,
                            }
                        }
                    })
                }   
            }
        },
        methods:{
            horisontalCenter(id){
                var obj = this.views.find(e => e.name == id);

                var centerX = (500 - obj.w)/2+(0.01*Math.random());

                obj.static.x = centerX;
                obj.x = centerX.toFixed();
            },

            validColor(hex){
                if(hex.length!=7 || hex[0]!='#')return false;
                var pass = true;
                [...hex.slice(1)].forEach((i)=>{
                    if(!'1234567890abcdef'.includes(i)){
                        pass = false;
                    }
                });
                return pass;
            },

            fileChangeHanler(e,name){
                var obj0 = this.views.find(i=>i.name == name);
                var obj1 = this.views.find(i=>i.name == name);
                fileToURL(e.target.files[0],(data)=>{
                    obj0.img = data;
                    obj1.img = data;
                });
            },

            copyHandler(){
                navigator.clipboard.writeText(JSON.stringify(this.result));
            },

            movedHandler(data, name){
                var obj = this.views.find((e)=>e.name == name);

                obj.x = data.left;
                obj.y = data.top; 
                obj.w = data.width;
                obj.h = data.height;
            },

            refreshViews(){
                var arr = [...new Set(this.textViews.replaceAll(' ','').split(',').filter(e => e.length>0))];
                var existing = this.views.map(e => e.name);
                
                //remove
                    this.views = this.views.filter(e => arr.includes(e.name));
                
                //addNew
                    arr.filter(e => !existing.includes(e)).forEach((e)=>{
                        this.views.push(this.newView(e));
                    });

                // this.views.push(this.newView('dod'));
            },
            newView(name){
                return {name: name, img: '', x:200, y:200, w:100, h: 100, static: {x:200, y:200, w:100, h: 100}}
            },
        }
    }
</script>

<style scoped lang="scss">
    .global_wr{
        display: flex;
        flex-wrap: wrap;

        >*{
            margin-left: 20px;
        }
    }

    .input_wr{
        margin-bottom: 10px;
        display: flex;

        input{
            margin-left: 10px;
        }
    }

    [type="checkbox"]{
        cursor: pointer;
    }

    label{
        cursor: pointer;
    }

    .container{
        width: 500px;
        height: 500px;
        position: relative;

        &[undisplayed]{
            opacity: 0;
            position: fixed;
            top: 0;
            left: 0;
            pointer-events: none;
        }

        &:after{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 2px solid black;
            z-index: 6;
            pointer-events: none;
        }

        .text{
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .fixedDirections{
            position: absolute;
            top: 0;
            left: 0;
            text-decoration: underline;
            cursor: pointer;
            z-index: 5;
            padding: 5px;
            background: #ffffff88;
        }
    }
    .area{
        width: 100%;
        height: 100%;
        position: relative;

        &:before,
	    &:after{
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
        }

        &:before{
            border: 1px solid white;
        }

        &:after{
            border: 1px dashed #000;
        }
    }

    .viewSwitch{
        display: flex;
        flex-wrap: wrap;
        height: 30px;
        border: 2px solid black;
        width: max-content;
        margin-bottom: 10px;

        label{
            height: 100%;
            padding: 0 15px;
            display: flex;
            align-items: center;

            &[active]{
                color: #fff;
                background: #000;
            }
        }

        input{
            display: none;
        }
    }

    .colors{
        display: flex;
        flex-wrap: wrap;
        margin: -2.5px;
        margin-top: 2.5px;
        max-width: 240px;

        .block{
            width: 15px;
            height: 15px;
            margin: 2.5px;
            border: 1px solid black;
        }
    }

    .result{
        max-width: 100vw;
        word-break: break-word;
    }
    

</style>

<!--<script>
    function getAllColors(){
        const rgba2hex = (rgba) => `#${rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/).slice(1).map((n, i) => (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n)).toString(16).padStart(2, '0').replace('NaN', '')).join('')}`

        var a = [];

        $('li.color').each((i,obj)=>{
            a.push(rgba2hex($(obj).css('background-color')));
        });

        return ''+[...new Set(a)];
    }
</script>-->