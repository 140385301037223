import {createWebHistory, createRouter } from 'vue-router';
import MVPindex from '@/pages/MVP-index.vue';
import Builder from '@/pages/Builder.vue';
import ProductBuilder from '@/pages/ProductBuilder.vue';

const routes = [
	{
		path: '/',
		name: "Builder",
		component: Builder
	},
	{
		path: '/mvp',
		name: "mvp-index",
		component: MVPindex
	},
	{
		path: '/builder',
		name: "ProductBuilder",
		component: ProductBuilder
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

export default router;
