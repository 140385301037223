import $ from 'jquery';

export default function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

export function fileToURL(file, callback){
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    callback(reader.result);
  };
}

export function dimFromUrl(_src,_callback){
  var img = document.createElement('img');
  img.src=_src;
  img.onload = function () { _callback(img.width,img.height) };
}