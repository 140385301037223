<template>
	<div class="modal" :show="modalShow||null" @click.self="closeModal">
		<div class="container">
			<h2>ADD NFT</h2>
			<div class="filters">
				<select class="filter" v-model="dropFilter">
					<option value="">Select collection</option>
					<option v-for="i in collections" :key="i" :value="i">{{i}}</option>
				</select>
				<input  class="filter" v-model="textFilter" type="text" placeholder="Search item">
			</div>
			<div class="list">
				<p v-if="!modalNFTs.length">No NFTs to add</p>
				<div v-for="nft in modalNFTs" :key="nft.id" class="block" @click="chooseNFT(nft.id)">
					<div class="img-wr"><img :src="nft.img" :picid="nft.id" alt=""></div>
					<p>{{nft.name}}</p>
				</div>
			</div>
		</div>
	</div>

	<template v-if="!address">
		<template v-if="isMetaMaskInstalled">
			<div class="btn" @click="connect">Connect wallet</div>
			<div v-if="unloggedErr">Check if you are logged in to the Metamask Extension</div>
		</template>
		<template v-else>
	        <div class="input-wrapper" style="text-align: center">
				Please use Firefox or a Chromium-based browser with <a href="https://metamask.io/" target="_blank">Metamask hot wallet</a> installed
	        </div>
	    </template>
	</template>
	
    <template v-else>
    	<p>Your wallet: {{address}}</p>
    	<template v-if="hasToadzRequest">
    		<h1>Checking your NFTs...</h1>
    	</template>
    	<template v-if="!hasToadzRequest && !hasToadz">
    		<p>
    			You do not have any ToadHeadz in your wallet.<br>
				Mint it to get access to the print-builder
			</p>
    	</template>
		<template v-if="!hasToadzRequest && hasToadz">
			<div class="btn" @click="openModal">Add NFT</div>
			<div class="nftList">
				<div v-for="nft in chosenNFTs" :key="nft.id" class="block">
					<img :src="nft.img" alt="">
					<p>{{nft.name}}</p>
					<div class="remove" @click="removeNFT(nft.id)">X</div>
				</div>
			</div>
			<div class="editor-container" ref="editor">
				<image-editor ref="editorArea" :objects="editorNFTs" :product="{product}" @picturesPositions="(data)=>{editorData = data}"/>
			</div>
			<div class="btn" @click="confirmHandler">Confirm</div>
		</template>
    </template>

</template>

<style scoped src="@/style/mvp.css"></style>

<script>
	import {checkNFTs, getAllNFTs, getProducts} from "@/script/api.js";
	import ImageEditor from "@/components/ImageEditor.vue";
	import $ from 'jquery';
	import html2canvas from 'html2canvas';
	

	var {ethereum} = window
	export default{
		components:{
			ImageEditor
		},
		data(){
			return{
				address: '',

				unloggedErr: false,

				hasToadzRequest: true,
				hasToadz: false,

				dropFilter: '',
				textFilter: '',

				modalShow: false,
				
				allNFTs: [],
				chosenNFTs: [],

				editorData: [],
				
				allProducts: [],
				product: null
			}
		},
		mounted(){
			if(localStorage.address){
				this.address = localStorage.address;
				this.firstRequest();
			}

			getProducts((data)=>{
				this.allProducts = data;
			});
		},
		methods:{
			connect(){
				ethereum
				.request({method: 'eth_requestAccounts'})
				.then(this.handleAccountsChanged)
				.catch((error) => {
					if (error.code === 4001) {
						console.log('Please connect to MetaMask.');
					}else if(error.code === -32002){
						this.unloggedErr = true;
					} else {
						console.error(error);
					}
				});
			},
			async handleAccountsChanged(address) {
				this.address = address[0];
				this.firstRequest()
			},
			firstRequest(){
				checkNFTs(this.address, "toadheadz", (resp)=>{
					this.hasToadzRequest = false;
					this.hasToadz = resp;
				});
			},
			secondRequest(){
				getAllNFTs(this.address, (resp)=>{
					this.allNFTs = resp;
					this.allNFTs.push({
						id: 'test',
						name: 'test',
						img: 'int.jpg',
						collection: 'test'
					});
				})
			},

			chooseNFT(id){
				this.chosenNFTs.push(this.allNFTs.find((i)=>i.id === id));
				this.closeModal();
			},
			removeNFT(id){
				var ids = this.chosenNFTs.map((i)=>i.id);
				this.chosenNFTs.splice(ids.indexOf(id),1);
			},

			closeModal(){
				this.modalShow = false;
				this.dropFilter = '';
				this.textFilter = '';
			},
			openModal(){
				this.modalShow = true;
			},
			
			// showImage(){
			// 	html2canvas(this.$refs.editor).then((canvas)=>{
			// 		this.image = canvas.toDataURL("image/jpeg");
			// 	});
			// },

			confirmHandler(){
				console.log(this.$refs.editorArea.returnData);
			}
		},
		computed:{
			isMetaMaskInstalled() {
				return ethereum && ethereum.isMetaMask
			},
			collections(){
				return [...new Set(this.allNFTs.map(i => i.collection))];
			},
			filters(){
				return {drop: this.dropFilter.toUpperCase(), text: this.textFilter.toUpperCase()};
			},
			modalNFTs(){
				var compare = this.chosenNFTs.map(i => i.id);
				return this.allNFTs.filter(
					(i) => {
						return (compare.indexOf(i.id) === -1) && 
								(this.dropFilter===""?true:i.collection.toUpperCase().includes(this.filters.drop)) &&
								(this.textFilter===""?true:i.name.toUpperCase().includes(this.filters.text))
					;}
				);
			},
			editorNFTs(){
				var res = [];
				this.chosenNFTs.forEach((i)=>{
					var obj = i;

					obj.w = $(`[picid="${obj.id}"]`).width();
					obj.h = $(`[picid="${obj.id}"]`).height();

					res.push(obj);
				});
				return res;
			}		
		},
		watch:{
			address(){
				localStorage.address = this.address;
			},
			hasToadz(){
				if(this.hasToadz)this.secondRequest();
			}
		}

	}
</script>