<template>
	<div class="ImageEditor" ref="container" :cheese="finalPhoto||null">
		<div class="backdrop">
			<img :src="product.background.img" alt="Your item">
		</div>

		<div 
			class="actualArea" 
			:style="`
				width: ${area.w}px; 
				height: ${area.h}px;
				top: ${area.y}px;
				left: ${area.x}px;
			`"
		>
			<img 
				v-for="i in objectsPosPic" 
				:key="i.id"
				:imgid="i.id"
				:src="i.img" 
				alt="" 

				:style="`
					position: absolute;
					transform-origin: center center;
					width: ${i.w}px; 
					height: ${i.h}px;
					top: ${i.y- area.y}px;
					left: ${i.x - area.x}px;
					transform: rotate(${i.deg}deg);
				`"

			>
		</div>

		<div class="overlayArea" style="width: 100%; height: 100%;">
			<vue-drag-resize-rotate
				v-for="i in objectsPos" 
				:key="i.id"
				:imgid="i.id"
				:x="i.x"
			    :y="i.y"
			    :w="i.w"
			    :h="i.h"
			    :deg="i.deg"
			    :aspectRatio="true"
			    :parentLimitation="true"

			    @mousedown="focusHandler(i.id)"

			    @resizing="(data)=>{movedHandler(data,i.id)}" 
		        @dragging="(data)=>{movedHandler(data,i.id)}" 
		        @rotating="(data)=>{movedHandler(data,i.id)}"
			></vue-drag-resize-rotate>
		</div>

		
			
		
	</div>
</template>

<script>
	import $ from 'jquery';
	import VueDragResizeRotate from '@pinkcao/vue-drag-resize-rotate/packages/vueDragResizeRotate/vueDragResizeRotate';
	import toDataUrl from '@/script/toDataUrl.js'
	import html2canvas from "html2canvas";

	export default{
		components:{
            VueDragResizeRotate,
        },
		props:{
			objects: Array,
			product: Object
		},
		emits:{
			'picturesPositions': true
		},
		data(){
			return {
				objectsPos:[],
				objectsPosPic:[],

				finalPhoto: false
			}
		},
		methods:{
			updateObjects(arr){
				this.objectsPos.forEach((obj,i)=>{
					var ref = this.objectsPosPic[i];
					obj.w = ref.w;
					obj.h = ref.h;
					obj.x = ref.x;
					obj.y = ref.y;
					obj.deg = ref.deg;
				});

				/*remove extra*/
					var newIds = arr.map(i=>i.id);
					this.objectsPos = this.objectsPos.filter(i => newIds.includes(i.id));

				/*filter new*/
					var currentIds = this.objectsPos.map(i=>i.id);
					arr = arr.filter(i => !currentIds.includes(i.id));

					arr.forEach((obj)=>{
						var vanilla = this.vanillaPosition(obj.w, obj.h);

						obj.w = vanilla.w;
						obj.h = vanilla.h;
						obj.x = vanilla.x;
						obj.y = vanilla.y;
						obj.deg = 0
					});

					this.objectsPos = [...this.objectsPos, ...arr];
					this.objectsPosPic = this.objectsPos.map(i => {
						return {
							id: i.id, 
							h: i.h, 
							w: i.w, 
							x: i.x, 
							y: i.y, 
							deg: i.deg, 
							img: i.img
						}
					});

					this.objectsPosPic.forEach((obj)=>{
						toDataUrl(obj.img, (data)=>{
							obj.img = data;
						}); 
					});

			},

			movedHandler(data,id){
				var obj = this.objectsPosPic.find((e)=>e.id == id);
				if (typeof data === 'object'){
					obj.x = data.left;
					obj.y = data.top; 
					obj.w = data.width;
					obj.h = data.height;
				}else if (typeof data === 'number'){
					obj.deg = data;
					this.objectsPos.find((e)=>e.id == id).deg = data;
				}

				this.$emit('picturesPositions',this.returnData);
			},

			vanillaPosition(width, height){
				var newWidth = this.canvasSize.w*.2;
				var newHeight = this.computeHeight(width, height, newWidth);
				
				return {
					w: newWidth,
					h: newHeight,
					x: this.computeX(newHeight),
					y: this.computeY(newWidth)
				}
			},
			computeHeight(width, height, newWidth){
				return (newWidth * height) / width;
			},
			computeY(height){
				return (this.canvasSize.h-height)/2;
			},
			computeX(width){
				return (this.canvasSize.w-width)/2;
			},

			focusHandler(id){
				var obj = $(`[imgid="${id}"]`);
				obj.siblings().removeClass('topPic');
				obj.addClass('topPic');
			},

			getResultNFT(cb){
				this.finalPhoto = true;
				html2canvas(this.$refs.container).then((canvas)=>{
					var res = {};
					res.nft = this.returnData.map(e => {
						return {
							img: e.img,
							params: {
								w: e.w,
								h: e.h,
								x: e.x,
								y: e.y,
								deg: e.deg
							}
						}
					});
					
					res.photo = canvas.toDataURL("image/jpeg");
					this.finalPhoto = false;
					cb(res);
			 	});
			}
		},
		computed:{
			canvasSize(){
				return {
					w: $(this.$refs.container).width(),
					h: $(this.$refs.container).height()
				}
			},
			returnData(){
				return this.objectsPosPic.map(i => {
					return {
						id: i.id, 
						h: (i.h)*2, 
						w: (i.w)*2, 
						x: (i.x - this.area.x)*2, 
						y: (i.y - this.area.y)*2, 
						deg: i.deg, 
						img: i.img
					}
				});
			},
			area(){
				return{
					w: this.product.area.w/2,
					h: this.product.area.h/2,
					x: this.product.area.x/2,
					y: this.product.area.y/2
				}
			}
		},
		watch:{
			objects(){
				this.updateObjects(this.objects);
			},
		}
	}
</script>

<style scoped lang="scss">
	.ImageEditor{
		width: 100%;
		height: 100%;
		position: relative;

		.backdrop img{
			pointer-events: none;
			height: 100%;
			width: 100%;
			object-fit: contain;
		}

		.actualArea{
			overflow: hidden;
			position: absolute;
			pointer-events: none;

			&::before,
			&::after{
				content: '';
				position: absolute;
				border: 1px solid white;
				height: calc(100%);
				width: calc(100%);
			}

			&:after{
				border: 1px dashed #000;
			}
		}

		.overlayArea{
			overflow: hidden;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		&[cheese]{
			.actualArea{
				&::before,
				&::after{
					display: none;
				}
			}

			.overlayArea{
				display: none;
			}
		}
	}


	.vdr{
		outline: none;
	}
	.topPic{
		z-index: 1!important;
	}
</style>