<template>
  <div class="order">
    <div class="size">
      <label for="size" class="label size__label">Size</label>
      <select id="size" class="select" v-model="data.size" @change="sizeChange(data.size)">
        <option v-for="size in data.sizes" :key="size" :value="size" class="size__option">{{size}}</option>
      </select>
    </div>

    <div class="quantity">
      <span class="quantity__label">Quantity</span>
      <div class="quantity__number">
        <div @click="reduceQuantity" class="quantity__btn quantity__btn_minus"></div>
        <span class="quantity__current">{{data.number}}</span>
        <div @click="increaseQuantity" class="quantity__btn quantity__btn_plus"></div>
      </div>
    </div>

      <button class="button button__order" @click="$emit('triggerConfirm')">Order</button>
    </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  emits:{
    'triggerConfirm': true,
    'changeNumber':true,
    'changeSize':true,
  },
  methods: {
    reduceQuantity() {
      if (this.data.number > 1) this.$emit('changeNumber', this.data.number -= 1);
    },
    increaseQuantity() {
      this.$emit('changeNumber', this.data.number += 1)
    },
    sizeChange(s){
      this.$emit('changeSize', s)
    }
  }
}
</script>

<style lang="scss" scoped>
.order {
  grid-area: params;
  display: flex;
  flex-direction: column;
  align-self: end;
}

.size {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  &__label {
    margin-bottom: 5px;
  }
}

.quantity {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  &__label {
    margin-right: 16px;
    font-family: inherit;
    font-size: 15px;
    line-height: 24px;
  }
  &__number {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid #000;
  }
  &__btn {
    position: relative;
    width: 42px;
    height: 42px;
    flex-shrink: 0;
    cursor: pointer;

    &_minus:before, 
    &_plus:before, 
    &_plus:after{
      content: '';
      height: 1px;
      width: 12px;
      position: absolute;
      top: 0;bottom: 0;right: 0;left: 0;
      margin: auto;
      background-color: #000;
    }

    &_plus:after{
      transform: rotate(90deg);
    }
  }
}
</style>