<template>
  <div :class="['modal-overlay', {'modal-overlay_show': this.isOpen === true}]" @click.self="close">
    <div class="modal">
      <div class="modal__header">
        <button @click="close" class="modal__close-btn">
          <div class="modal__cross"></div>
        </button>
        <h2 class="modal__title">add nft</h2>
        <form class="modal__search-wrap">
          <select v-model="dropFilter" name="" id="" class="select modal__select-collection">
            <option value="">Select collection</option>
            <option v-for="collection in collections" :key="collection" :value="collection">{{ collection }}</option>
          </select>
          <input v-model="textFilter" type="text" class="input modal__search-nft" placeholder="Search item">
        </form>
      </div>
      <div class="modal__grid">
        <p v-if="!modalNFTs.length">No NFTs to add</p>
				<div v-for="nft in modalNFTs" :key="nft.id" class="block modal__item" @click="chooseNFT(nft.id)">
					<div class="img-wr modal__item-img"><img :src="nft.img" :picid="nft.id" alt=""></div>
					<p class="modal__item-caption">{{nft.name}}</p>
				</div>
      </div>
    </div>
  </div>
</template>

<script>
// import _ from "loadash";

export default {
  props: {
    allNFTs: Array,
    chosenNFTs: Array,
    isOpen: Boolean
  },
  emits:{
    'close': true
  },
  data() {
    return {
      dropFilter: '',
			textFilter: ''
    }
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    chooseNFT(id){
      this.chosenNFTs.push(this.allNFTs.find((i)=>i.id === id));
      this.close();
    },
    close(){
      this.$emit('close');
      this.dropFilter = '';
      this.textFilter = '';
    }
  },
  computed:{
    modalNFTs() {
      var compare = this.chosenNFTs.map((i) => i.id);
      return this.allNFTs.filter((i) => {
        return (
          compare.indexOf(i.id) === -1 &&
          (this.dropFilter === ""
            ? true
            : i.collection
              .toUpperCase()
              .includes(this.filters.drop)) &&
          (this.textFilter === ""
            ? true
            : i.name.toUpperCase().includes(this.filters.text))
        );
      });
    },
    collections(){
      return [...new Set(this.allNFTs.map(i => i.collection))];
    },
    filters(){
      return {drop: this.dropFilter.toUpperCase(), text: this.textFilter.toUpperCase()};
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-overlay {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 40px;
  background-color: #00000060;
  place-items: center;
  &_show {
    display: grid;
  }
}
.modal {
  position: relative;
  display: block;
  flex-direction: column;
  align-items: center;
  padding: 0 90px;
  max-width: 756px;
  max-height: 650px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #BFBB98;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 5;
  &__header {
    position: sticky;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0 24px;
    width: 100%;
    background-color: inherit;
    z-index: 6;
    position: sticky;
    top: 0;
  }
  &__close-btn {
    position: absolute;
    top: 30px;
    right: -66px;
    padding: 0;
    width: 18px;
    height: 18px;
    align-self: flex-end;
    border: none;
    background: transparent;
    cursor: pointer;
  }
  &__cross {
    &::before, &::after {
      position: absolute;
      content: '';
      top: 0;
      right: 50%;
      width: 2px;
      height: 20px;
      background-color: #000;
    }
    &::before {
      transform: rotateZ(45deg);
    }
    &::after {
      transform: rotateZ(-45deg);
    }
  }
  &__title {
    margin: 0 0 24px;
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    line-height: 30px;
    text-transform: uppercase;
  }
  &__search-wrap {
    width: 100%;
    display: flex;
    gap: 24px;
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(171px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(203px, 1fr));
    width: 100%;
    padding-bottom: 40px;
    column-gap: 24px;
    row-gap: 16px;
  }
  &__item {
    position: relative;
    cursor: pointer;
    &:hover &-overlay {
      visibility: visible;
      opacity: 1;
    }
    &:not(:hover) .img-wr:after,
    &:not(:hover) .img-wr:before{
      opacity: 0!important;
    }

  }
  &__item-img {
    position: relative;
    width: 100%;
    height: 171px;
    width: 171px;
    display: flex;
    align-content: center;
    justify-content: center;

    img{
      display: block;
      max-width: 100%;
      max-height: 100%;
      width: unset;
      height: unset;
    }

    &:before, 
    &:after{
      content: '';
      position: absolute;
      display: flex;
      transition: .3s;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    &:before{
      width: 100%;
      height: 100%;
      background: #00000080;
    }

    &:after{
      content: 'Add';
      width: 120px;
      height: 45px;
      background: #B04E85;
      align-items: center;
      justify-content: center;
      color: #FFF;
      font-size: 15px;
    }
  }
  &__item-caption {
    margin: 8px 0 0;
    font-family: inherit;
  }
  &__item-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000040;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in;
    .button__order {
      padding: 11px 47px;
    }
  }
}
</style>