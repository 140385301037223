<template>
  <div class="info">
    <div class="info__top">
      <p class="info__name">{{name}}</p>
      <!-- <span class="info__price">$19.00 USD</span> -->
    </div>
    <div class="info__bottom">
      <div class="your-design">
        <span class="your-design__caption">Your design</span>
        <div class="active_toads" :style="[chosenNFTs.length ? 'margin-bottom: 10px;' : '']">
          <div v-for="nft in chosenNFTs" :key="nft.name" class="block">
            <img :src="nft.img" alt="">
            <div class="name">{{nft.name}}</div>
            <div class="remove" @click="removeNFT(nft.id)"></div>
          </div>
        </div>
        <button @click="openAddNftModal" class="button your-design__btn">Add NFT</button>
      </div>
    </div>

    <modal :allNFTs="allNFTs" :chosenNFTs="chosenNFTs" :isOpen="isOpen" @close="closeAddNftModal"></modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue';

export default {
  components: { 
    Modal 
  },
  props: {
    allNFTs: Array,
    chosenNFTs: Array,
    name: String,
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    openAddNftModal() {
      this.isOpen = true
    },
    closeAddNftModal(){
      this.isOpen = false
    },
    removeNFT(id){
      var ids = this.chosenNFTs.map((i)=>i.id);
      this.chosenNFTs.splice(ids.indexOf(id),1);
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  grid-area: info;
  display: flex;
  flex-direction: column;
  &__top {
    margin-bottom: 17px;
  }
  &__name {
    margin: 0 0 16px;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
  }
  &__price {
    font-size: 15px;
    line-height: 20px;
  }
}

.your-design {
  display: flex;
  flex-direction: column;
  &__caption {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
  }
}

.active_toads{
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 400px;
  max-height: 84px;
  overflow-x: hidden;
  overflow-y: auto;
  
  .block{
    height: 32px;
    display: flex;
    align-items: center;

    :not(:last-child){
      margin-right: 7px;
    }

    img{
      height: 32px;
      width: 32px;
      object-fit: contain;
      flex-shrink: 0;
    }

    .name{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
    }

    .remove{
      position: relative;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &::before,
      &::after{
        content: "";
        position: absolute;
        height: 14px;
        width: 1px;
        background: #000;
      }

      &::before{
        transform: rotate(-45deg);
      }

      &::after{
        transform: rotate(45deg);
      }

      &:hover::before, &:hover::after {
        background: #B04E85;
      }
    }
  }
}
</style>