<template>
  <div class="wrap">
      <ul class="palette">
        <li @click="selectNew(color)" v-for="color in colors" :key="color" 
        :class="['palette__color', {'palette__color_active': chosen === color}]" 
        :style="{'background-color': color}"></li>
      </ul>
    </div>
</template>

<script>
export default {
  props: {
    colors: Array,
    chosen: String
  },
  emits:{
    'chosenChanged': true
  },
  methods:{
    selectNew(obj){
      this.$emit('chosenChanged',obj);
    }
  }
  
}
</script>

<style lang="scss" scoped>
  .wrap {
    width: 263px;
    margin-left: -8px;
    padding: 8px;
    overflow-y: auto;
    max-height: 500px;
  }

  .palette {
    grid-area: palette;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(5, 28px);
    place-items: center;
    gap: 22px;
    list-style: none;
    &__color {
      position: relative;
      width: 28px;
      min-width: 28px;
      min-height: 28px;
      border-radius: 50%;
      border: 1px solid #c1c1c130;
      cursor: pointer;
      &::before {
        display: none;
        position: absolute;
        content: '';
        top: -7px;
        left: -7px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid gray;
      }
      &:hover::before {
        display: block;
      }
      &_active::before {
        display: block;
        border: 1px solid #000;
      }
    }
  }
</style>