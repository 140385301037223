<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="sass">
#app
  font-family: "SF Mono", Menlo, Consolas, Monaco, "Liberation Mono", "Lucida Console", monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
  //font-family: 'PT Mono', monospace
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: #000
* ,
*::before,
*::after
  box-sizing: border-box

body 
  margin: 0
  padding: 0
  box-sizing: border-box
</style>